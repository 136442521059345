import ReactGA4 from 'react-ga4';

export function initializeAnalytics() {
    ReactGA4.initialize('G-LCLW13KEKW');
}

function ElevatteHost() {
    return window.location.hostname === 'elevatte.me';
}

function sendAnalytics(eventData) {
    if (ElevatteHost()) {
        ReactGA4.send(eventData);
    }
}

export function sendPageView() {
    sendAnalytics({hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title});
}

export function sendClick(event) {
    sendAnalytics({hitType: 'event', eventCategory: 'Interaction', eventAction: 'Click', eventLabel: event});
}
