import React from 'react'
import Hero from 'components/hero/OneColumnFullWidth'
import Footer from 'components/footers/SimpleFiveColumn.js'

import tw from 'twin.macro'
import { Container as ContainerBase, ContentWithPaddingLg } from 'components/misc/Layouts'

const Container = tw(ContainerBase)`mt-4 px-8`
const ContentContainer = tw.div`flex flex-row items-center justify-center flex-wrap justify-between`
const ContentSection = tw.div`pb-5`
const ContentHeader = tw.h1`text-xl font-extrabold mb-3`
const P = tw.p`my-2`
const Bold = tw.span`font-bold`
const Li = tw.li`list-disc ml-10`

export default () => {
  return (
    <main>
      <div id="home">
        <Hero heading="Politica de Privacidade da Elevatte" navLinks={[]} />
      </div>
      <div id="content">
        <Container>
          <ContentWithPaddingLg>
            <ContentContainer>
              <ContentSection>
                <ContentHeader>Bem-vindo à Elevatte!</ContentHeader>
                <P>
                  Estes Termos e Condições de Uso ("Termos") aplicam-se aos Candidatos Individuais
                  como usuários da plataforma e dos serviços oferecidos pela ELEVATTE LTDA,
                  ("Elevatte"), desenvolvedora e proprietária da Plataforma Elevatte LTDA (doravante
                  denominada "Elevatte LTDA").
                </P>
              </ContentSection>
              <ContentSection>
                <ContentHeader>Visão Geral</ContentHeader>
                <P>
                  Estes termos estabelecem as regras que devem ser seguidas ao utilizar a Elevatte
                  LTDA e podem ser consultados a qualquer momento pelo Usuário através da Elevatte
                  LTDA.
                </P>
                <P>
                  É essencial que você saiba que o Aviso de Privacidade da Plataforma Elevatte LTDA
                  é parte integrante destes Termos.
                </P>
                <P>
                  A compreensão e a aceitação destes Termos são absolutamente indispensáveis para o
                  uso da Elevatte LTDA e dos Serviços fornecidos pela Elevatte. Portanto, para uma
                  utilização adequada, você deve ler, certificar-se de que compreendeu e aceitar
                  todas as condições estabelecidas nestes Termos.
                </P>
              </ContentSection>
              <ContentSection>
                <ContentHeader>Definições</ContentHeader>
                <P>
                  <Bold>Banco de Talentos:</Bold> Um repositório onde são armazenadas informações
                  sobre candidatos que já se candidataram a posições na Empresa Cliente e/ou foram
                  convidados pela Empresa Cliente a ingressar no respectivo Banco de Talentos;
                </P>
                <P>
                  <Bold>Conteúdo:</Bold> Qualquer informação inserida pelos Usuários na Plataforma;
                </P>
                <P>
                  <Bold>CRM de Talentos:</Bold> Também conhecido como Gerenciamento de
                  Relacionamento com Candidatos, é um módulo pago da Elevatte LTDA para Empresas
                  Clientes que buscam adicionar talentos (candidatos em potencial) ao Banco de
                  Talentos e desenvolver um relacionamento para acelerar sua contratação;
                </P>
                <P>
                  <Bold>Dados:</Bold> Dados: Dados Pessoais e/ou Dados Sensíveis;
                </P>
                <P>
                  <Bold>Dados Pessoais:</Bold> Informações relacionadas a uma pessoa natural
                  identificada ou identificável;
                </P>
                <P>
                  <Bold>Empresa Cliente:</Bold> Refere-se à empresa que contrata os serviços
                  oferecidos pela Elevatte, especialmente os serviços relacionados à Elevatte LTDA
                  para anunciar oportunidades de emprego ou recrutar candidatos qualificados,
                  identificando talentos em potencial entre eles e selecionando-os para integrar sua
                  equipe. As vagas podem ser divulgadas na seção "Trabalhe Conosco" do site de
                  carreiras da Empresa Cliente;
                </P>
                <P>
                  <Bold>Elevatte:</Bold> Refere-se à empresa ELEVATTE LTDA, registrada sob o CNPJ
                  48.323.771/0001-46, com sede em AVENIDA PREFEITO OSMAR CUNHA, 416, SALA 1108,
                  CENTRO, na Cidade de Florianópolis, Estado de Santa Catarina, CEP 88015100.
                  Gestora da Elevatte LTDA;
                </P>
                <P>
                  <Bold>Elevatte LTDA:</Bold> Plataforma de Recrutamento e Seleção da Elevatte, uma
                  ferramenta utilizada por Empresas Clientes para gerenciar seus Processos de
                  Recrutamento;
                </P>
                <P>
                  <Bold>Inteligência Artificial:</Bold> Sistema computacional capaz de aprender e
                  executar tarefas que normalmente requerem inteligência humana;
                </P>
                <P>
                  <Bold>LGPD:</Bold> Lei Geral de Proteção de Dados - Lei nº 13.709, de 14 de agosto
                  de 2018;
                </P>
                <P>
                  <Bold>Candidatos Individuais:</Bold> Indivíduos que se candidatam e estão
                  interessados em ocupar determinada posição ou função por meio de processos
                  seletivos na Empresa;
                </P>
                <P>
                  <Bold>Processos de Recrutamento:</Bold> Conjunto de técnicas que auxiliam na
                  escolha dos candidatos mais adequados para uma determinada vaga. Por meio dele, é
                  possível identificar habilidades e competências, além de verificar quais perfis
                  são mais compatíveis com a função e a cultura da empresa;
                </P>
                <P>
                  <Bold>Usuários:</Bold> Candidatos Individuais e Empresas Clientes que utilizam a
                  Elevatte LTDA.
                </P>
                <P>
                  <Bold>Usuário da Empresa:</Bold> Colaborador da Empresa que utiliza a Elevatte
                  LTDA para processos de recrutamento e/ou a Elevatte para processos de admissão.
                </P>
              </ContentSection>
              <ContentSection>
                <ContentHeader>Aplicação destes Termos</ContentHeader>
                <P>
                  Esses Termos serão aplicáveis a você assim que começar a utilizar a Plataforma
                  Elevatte LTDA criando uma conta como Candidato Individual e cessarão apenas quando
                  seu relacionamento com a Elevatte terminar, especialmente nos seguintes casos:
                </P>
                <P>
                  <ul>
                    <Li>Devido ao cancelamento de sua conta pela Elevatte; </Li>
                    <Li>Devido ao encerramento de sua conta por você. </Li>
                  </ul>
                </P>
                <P>
                  Se sua conta for reativada posteriormente, esses Termos voltarão a se aplicar a
                  você, em sua versão atualizada.
                </P>
              </ContentSection>
              <ContentSection>
                <ContentHeader>Serviços fornecidos pela Elevatte LTDA</ContentHeader>
                <P>A Elevatte oferece os seguintes serviços para Candidatos Individuais:</P>
                <P>
                  <ul>
                    <Li>
                      Portal de vagas para que você encontre oportunidades alinhadas com seus
                      interesses profissionais nas Empresas Clientes;
                    </Li>
                    <Li>
                      Identificação de suas características profissionais, por meio da realização de
                      testes fornecidos pela Elevatte ou terceiros;
                    </Li>
                    <Li>
                      Correspondência entre o perfil das Empresas Clientes que buscam Candidatos
                      Individuais e Candidatos Individuais que procuram uma nova posição, com
                      participação em processos seletivos a serem realizados quando houver vagas
                      disponíveis;
                    </Li>
                  </ul>
                </P>
              </ContentSection>
              <ContentSection>
                <ContentHeader>Condições de Acesso e Uso</ContentHeader>
                <P>
                  <Bold>Cadastro:</Bold> Seu cadastro deve ser completado ao acessar a Elevatte LTDA
                  e só será confirmado após o preenchimento de todos os campos solicitados. Ao se
                  cadastrar como resultado de um Processo Seletivo, você só terá acesso a todos os
                  recursos da Elevatte LTDA após a conclusão do processo de cadastro e das etapas de
                  testes disponibilizadas pelas Empresas Clientes. Se você se candidatar a mais de
                  um Processo Seletivo durante o cadastro, terá acesso a todos os recursos da
                  Elevatte LTDA após concluir as etapas de cadastro de um deles. Seu cadastro é
                  único e será utilizado em todos os processos seletivos em que você se inscrever;
                </P>
                <P>
                  <Bold>Conta de Acesso:</Bold> Uma conta de Usuário será fornecida para o uso da
                  Elevatte LTDA, que será acessada por meio de e-mail (login) e senha;
                </P>
                <P>
                  <Bold>Acesso individual e intransferível:</Bold> É proibido divulgar seus dados de
                  acesso a terceiros, sendo o Usuário totalmente responsável pelo uso dos mesmos. Em
                  caso de uso não autorizado de sua conta, você deve nos informar imediatamente,
                  através de contato@elevatte.me, assumindo todos os riscos decorrentes de qualquer
                  acesso não autorizado;
                </P>
                <P>
                  <Bold>Autenticidade, Atualização e Exatidão dos dados:</Bold> Todas as informações
                  fornecidas pelo Usuário para acesso e uso do Serviço devem ser verdadeiras. O
                  Usuário é responsável pela precisão, correção e autenticidade dos dados
                  registrados, bem como pela guarda, confidencialidade e uso adequado do login e das
                  senhas cadastrados, eximindo a Elevatte de qualquer responsabilidade. É
                  responsabilidade exclusiva do Usuário manter qualquer informação fornecida por
                  meio da Elevatte LTDA permanentemente atualizada para refletir sempre os dados
                  reais do Usuário;
                </P>
                <P>
                  <Bold>Acesso por meio de redes sociais:</Bold> Você também pode acessar sua conta
                  através de redes sociais que possuem uma conexão pré-estabelecida com a Elevatte
                  LTDA, como LinkedIn e Facebook. Nesse caso, os dados fornecidos nesses sites podem
                  estar sujeitos aos termos de uso e políticas de privacidade dessas plataformas;
                </P>
                <P>
                  <Bold>Indicação para a vaga:</Bold> Se, ao se cadastrar em um processo seletivo
                  específico, você indicar que está sendo indicado para a vaga por um colaborador
                  atual da Empresa Cliente que abriu o Processo Seletivo, a indicação deve ser
                  validada, através da Elevatte LTDA, pela pessoa que o indicou;
                </P>
                <P>
                  <Bold>Atribuição de Candidatos Individuais à vaga:</Bold> A Empresa Cliente pode
                  convidar você a participar de Bancos de Talentos e Processos Seletivos com vagas
                  abertas que correspondam ao seu perfil e habilidades, sempre considerando seu
                  benefício e interesse em ser contratado;
                </P>
                <P>
                  <Bold>Banco de Talentos:</Bold> Ao se inscrever em um Processo Seletivo, você
                  passa a fazer parte do Banco de Talentos da Empresa Cliente, e seus dados pessoais
                  serão retidos mesmo após a conclusão do Processo Seletivo. A qualquer momento,
                  você pode solicitar a exclusão de sua conta, conforme indicado em nosso Aviso de
                  Privacidade;
                </P>
                <P>
                  <Bold>CRM de Talentos:</Bold> A Empresa Cliente pode, a seu critério, desde que
                  cumpra a legislação aplicável, adicionar novos talentos (candidatos potenciais) ao
                  seu Banco de Talentos com o objetivo de desenvolver um relacionamento para
                  acelerar sua chegada a uma posição.
                </P>
              </ContentSection>
              <ContentSection>
                <ContentHeader>Diretrizes Gerais de Uso</ContentHeader>
                <P>
                  Todas as informações, dados, textos, programas, músicas, sons, fotografias,
                  gráficos, vídeos, anúncios, mensagens e outros materiais que possam ser enviados,
                  inseridos ou exibidos pela Empresa Cliente são de responsabilidade exclusiva do
                  autor desse conteúdo.
                </P>
                <P>
                  Candidatos Individuais devem utilizar a Elevatte LTDA apenas para finalidades
                  lícitas e autorizadas. A Elevatte tem o direito de tomar medidas, incluindo
                  suspensão ou cancelamento do cadastro, temporária ou permanentemente, e outras
                  ações apropriadas se o Candidato usar a Elevatte LTDA em violação a esses Termos e
                  de maneira inadequada, como fornecer informações falsas ou comprometer a
                  integridade da Elevatte. Isso também se aplica a ações que possam criar
                  responsabilidades para a Elevatte ou para a Empresa Cliente, incluindo difamação
                  da marca, imagem pública, ou de seus provedores de Internet e outros fornecedores.
                </P>
                <P>
                  O Usuário da Empresa é responsável, em nome da Empresa Cliente, por utilizar a
                  Elevatte LTDA apenas para finalidades legais e autorizadas, e a Elevatte não tem
                  controle sobre as oportunidades de emprego, o Conteúdo ou as questões incluídas
                  pela Empresa Cliente. A Elevatte pode tomar as ações apropriadas se o Usuário da
                  Empresa utilizar a Elevatte LTDA em violação a esses Termos.
                </P>
                <P>Ao utilizar a Plataforma, o Candidato Individual pode:</P>
                <P>
                  <ul>
                    <Li>Pesquisar ofertas de emprego através de filtros;</Li>
                    <Li>
                      Compartilhar nas redes sociais todo o conteúdo disponível dentro da Plataforma
                      que estiver disponível para essa ação;
                    </Li>
                    <Li>Inscrever-se em vagas publicadas pelas Empresas Clientes;</Li>
                    <Li>
                      Alterar, retificar ou excluir seus dados pessoais, de acordo com o Aviso de
                      Privacidade vigente.
                    </Li>
                  </ul>
                </P>
                <P>
                  É proibido para o Candidato Individual (e Usuários em geral, conforme aplicável):
                </P>
                <P>
                  <ul>
                    <Li>
                      Incluir currículos ou candidatar-se a oportunidades de emprego em nome de
                      terceiros;
                    </Li>
                    <Li>
                      Publicar mais de uma cópia do mesmo currículo como "público" ao mesmo tempo;
                    </Li>
                    <Li>
                      Violar/tentar violar a segurança da Elevatte, tentando investigar, copiar e
                      testar vulnerabilidades do sistema, brechas de segurança ou medidas de
                      autenticação sem autorização;
                    </Li>
                    <Li>
                      Falsificar cabeçalhos TCP/IP ou qualquer parte das informações de cabeçalho em
                      qualquer e-mail ou postagens em grupos de notícias;
                    </Li>
                    <Li>
                      Agregar, copiar ou duplicar partes da Elevatte LTDA, incluindo oportunidades
                      de emprego expiradas;
                    </Li>
                    <Li>
                      Adaptar ou fornecer link para qualquer Conteúdo da Elevatte ou informações
                      disponíveis no Site ou no Aplicativo, a menos que permitido por este Termo;
                    </Li>
                    <Li>
                      Publicar conteúdo ou materiais que promovam ou endossem informações
                      falsas/enganosas ou atividades ilegais, bem como informações que ofereçam
                      instruções para atividades ilegais ou proibidas conforme este Termo;
                    </Li>
                    <Li>
                      Acessar dados de terceiros, servidores ou contas que o Usuário não esteja
                      autorizado a acessar;
                    </Li>
                    <Li>
                      Publicar conteúdo contendo páginas de acesso restrito ou protegidas por senha,
                      bem como páginas ou imagens ocultas;
                    </Li>
                    <Li>
                      Tentar interferir com os serviços para Usuários, administradores ou redes,
                      incluindo, mas não se limitando a métodos de envio de vírus ao site,
                      sobrecarga, acumulação de mensagens sem conteúdo, spam, "bombas de e-mail" ou
                      travamentos;
                    </Li>
                    <Li>
                      Utilizar a Elevatte LTDA de maneira ilícita para qualquer atividade ilegal,
                      incluir ou submeter qualquer tipo de conteúdo difamatório, acusatório,
                      implícita ou explicitamente ofensivo, vulgar, obsceno, ameaçador, abusivo,
                      irado, racista, discriminatório e assediante, currículo ou postagens de
                      emprego. Links para conteúdo pornográfico, indecente ou explicitamente sexual
                      de qualquer tipo também não são permitidos; conforme determinado a critério da
                      Elevatte.
                    </Li>
                  </ul>
                </P>
                <P>
                  Violações à segurança do sistema e da rede podem resultar em processos civis e/ou
                  criminais: A Elevatte investigará ocorrências que possam resultar em violações,
                  incluindo, mas não se limitando às mencionadas acima, e poderá engajar-se e
                  cooperar com autoridades e órgãos de segurança pública na punição dos Usuários que
                  se envolvam em atos de violação.
                </P>
                <P>
                  Em caso de falsificação de documentos ou fraude, a Elevatte pode, a seu critério,
                  notificar e até rescindir o contrato com a Empresa, bem como constituir potenciais
                  crimes previstos no Código Penal, sujeitos a penalização.
                </P>
              </ContentSection>
              <ContentSection>
                <ContentHeader>Funcionalidades</ContentHeader>
                <P>
                  <Bold>Mapeamento de Perfil e Testes Na Elevatte:</Bold> você realizará testes
                  online usados para mapear seu perfil, potencial e/ou conhecimentos gerais. Os
                  testes fornecidos serão realizados apenas uma vez e serão utilizados para todos os
                  processos seletivos dos quais você participar. As pontuações dos testes podem ser
                  reutilizadas.
                </P>
                <P>
                  Os resultados dos testes serão disponibilizados apenas para as Empresas Clientes
                  nos processos seletivos em que você participar. Esses resultados ajudarão as
                  Empresas Clientes na seleção de Candidatos Individuais. Quaisquer comentários ou
                  recomendações em relação a esses testes feitos pela Empresa Cliente serão
                  acessíveis apenas a eles e aos funcionários designados e autorizados a usar a
                  Elevatte LTDA.
                </P>
                <P>
                  Etapa de Gravação de Vídeo A gravação de vídeo, se solicitada pelo processo
                  seletivo, pode ser realizada através da Elevatte LTDA. Os vídeos devem ser
                  gravados de acordo com parâmetros e diretrizes preestabelecidos na Elevatte LTDA.
                </P>
                <P>
                  A Elevatte removerá da Elevatte LTDA qualquer vídeo que, a seu critério, contenha
                  conteúdo inapropriado ou ilegal, ou conteúdo que desacredite as empresas clientes
                  da Elevatte. Nesses casos, o Candidato Individual responsável pela gravação terá
                  seu cadastro excluído e poderá ser responsabilizado civil e criminalmente por
                  quaisquer danos causados pelo conteúdo do vídeo a terceiros.
                </P>
                <P>
                  Ao enviar uma gravação de vídeo para uma Empresa Cliente, você autoriza a Elevatte
                  a compartilhar sua imagem e voz com essa empresa.
                </P>
                <P>
                  Os vídeos podem ser reutilizados em futuros processos seletivos para vagas abertas
                  por outras Empresas Clientes, se assim desejar.
                </P>
                <P>
                  Os vídeos, bem como quaisquer comentários ou recomendações feitos pela Empresa
                  Cliente sobre os vídeos, serão acessíveis apenas a eles e aos funcionários
                  designados e autorizados a usar a Elevatte LTDA.
                </P>
                <P>
                  <Bold>Etapa de Gravação de Vídeo:</Bold> A gravação de vídeo, se solicitada pelo
                  processo seletivo, pode ser realizada através da Elevatte LTDA. Os vídeos devem
                  ser gravados de acordo com parâmetros e diretrizes preestabelecidos na Elevatte
                  LTDA.
                </P>
                <P>
                  A Elevatte removerá da Elevatte LTDA qualquer vídeo que, a seu critério, contenha
                  conteúdo inapropriado ou ilegal, ou conteúdo que desacredite as empresas clientes
                  da Elevatte. Nesses casos, o Candidato Individual responsável pela gravação terá
                  seu cadastro excluído e poderá ser responsabilizado civil e criminalmente por
                  quaisquer danos causados pelo conteúdo do vídeo a terceiros.
                </P>
                <P>
                  Ao enviar uma gravação de vídeo para uma Empresa Cliente, você autoriza a Elevatte
                  a compartilhar sua imagem e voz com essa empresa.
                </P>
                <P>
                  Os vídeos podem ser reutilizados em futuros processos seletivos para vagas abertas
                  por outras Empresas Clientes, se assim desejar.
                </P>
                <P>
                  Os vídeos, bem como quaisquer comentários ou recomendações feitos pela Empresa
                  Cliente sobre os vídeos, serão acessíveis apenas a eles e aos funcionários
                  designados e autorizados a usar a Elevatte LTDA.
                </P>
                <P>
                  <Bold>Agendamento de Entrevista:</Bold> Se a Empresa Cliente selecionar esta
                  opção, um e-mail pode ser enviado a você indicando as datas disponíveis para o
                  agendamento de entrevistas. Uma vez que você selecione uma data, é
                  responsabilidade da Empresa Cliente, e não da Elevatte, confirmar a entrevista.
                </P>
                <P>
                  Essa etapa ocorre apenas entre a Empresa e o Candidato Individual. A Elevatte não
                  se responsabiliza pela presença das partes ou pelo feedback da entrevista
                  realizada.
                </P>
                <P>
                  <Bold>Perguntas Adicionais:</Bold> A Empresa Cliente pode fazer perguntas
                  adicionais, ou seja, além das configuradas por padrão na Elevatte LTDA, e
                  solicitar dados adicionais por meio da Elevatte LTDA, sem interferência da
                  Elevatte.
                </P>
                <P>
                  <Bold>Recrutamento Interno:</Bold> Para participar de um processo de recrutamento
                  interno, o funcionário da Empresa deve ter uma conta na Elevatte LTDA e, portanto,
                  deve aceitar os termos destes Termos.
                </P>
                <P>
                  <Bold>Uso de Inteligência Artificial:</Bold> Ao analisar esses requisitos, o
                  perfil da Empresa Cliente, o currículo e outras informações fornecidas pelo
                  Candidato Individual (em seu perfil e durante o cadastro para esse processo
                  específico), a inteligência artificial da Elevatte apresenta ao recrutador os
                  indivíduos que se candidataram àquela posição, organizando-os com base na
                  afinidade desses critérios entre si, a fim de proporcionar mais oportunidades
                  àqueles que são mais compatíveis com a vaga.
                </P>
                <P>
                  Essa organização de candidatos não é estática e muda ao longo do processo
                  seletivo, de acordo com novas candidaturas ou desistências, e especialmente com a
                  interação do recrutador com os currículos, o que fornece à inteligência artificial
                  mais informações sobre as preferências da empresa.
                </P>
                <P>
                  Vale ressaltar que a Inteligência Artificial não elimina nenhum currículo
                  analisado, nem toma decisões de aprovação ou rejeição em relação a eles. Todas e
                  quaisquer decisões tomadas durante o processo seletivo são feitas diretamente pelo
                  recrutador e/ou gerente responsável pela vaga.
                </P>
                <P>
                  <Bold>Recomendação de Candidatos para Vagas:</Bold> A IA da Elevatte também avalia
                  candidatos cadastrados no banco de talentos de uma empresa e, para cada posição
                  disponível, faz recomendações de indivíduos mais adequados para a vaga. Tais
                  recomendações servem apenas como sugestões ao recrutador, e a decisão de incluir
                  ou não incluir um candidato individual em um determinado processo seletivo é
                  responsabilidade do profissional que conduz o recrutamento.
                </P>
                <P>
                  <Bold>Recomendação de Vagas aos Candidatos:</Bold> A IA da Elevatte pode fazer
                  recomendações de vagas abertas aos Candidatos Individuais, desde que estejam
                  cadastrados no Banco de Talentos da Empresa Cliente e sempre de acordo com as
                  informações do Currículo e da Descrição do Trabalho.
                </P>
              </ContentSection>
              <ContentSection>
                <ContentHeader>Suporte</ContentHeader>
                <P>
                  Candidatos Individuais podem entrar em contato com o Suporte da Elevatte abrindo
                  um chamado por meio de: contato@elevatte.me. As consultas também serão respondidas
                  via e-mail, de segunda a sexta-feira, das 9h às 18h, exceto feriados.
                </P>
                <P>
                  Para entrar em contato com a nossa central de ajuda, mande um email para
                  contato@elevatte.me.
                </P>
              </ContentSection>
              <ContentSection>
                <ContentHeader>
                  Responsabilidade da Elevatte através da Elevatte LTDA{' '}
                </ContentHeader>
                <P>A Elevatte é responsável por:</P>
                <P>
                  <ul>
                    <Li>
                      Oferecer uma ferramenta online para Empresas Clientes publicarem oportunidades
                      de emprego ou Bancos de Talentos, e para Candidatos Individuais buscarem e se
                      candidatarem a vagas de emprego;
                    </Li>
                    <Li>
                      Fornecer uma ferramenta para aplicação e gestão de candidaturas em Processos
                      Seletivos.
                    </Li>
                  </ul>
                </P>
                <P>
                  <Bold>A Elevatte NÃO é responsável por:</Bold>
                </P>
                <P>
                  <Bold>Abertura de vagas pelas Empresas Clientes:</Bold> A abertura de vagas de
                  emprego é de responsabilidade exclusiva da Empresa Cliente. A Elevatte não é
                  responsável pela qualidade, segurança ou legalidade das vagas oferecidas, pela
                  exatidão ou precisão dos anúncios, ou pela capacidade das Empresas Clientes de
                  fornecer oportunidades de emprego aos Candidatos Individuais. A publicação das
                  vagas e seu conteúdo são de responsabilidade da Empresa Cliente, incluindo o
                  modelo de contratação.
                </P>
                <P>
                  <Bold>Dados fornecidos pelos Candidatos Individuais:</Bold> Os dados fornecidos
                  pelo Candidato Individual são de sua exclusiva responsabilidade. A Elevatte não se
                  responsabiliza por informações incorretas que possam resultar na não seleção do
                  Candidato Individual para um determinado processo seletivo, desqualificação
                  subsequente de um processo seletivo no qual estava participando ou na
                  impossibilidade de contato com o Candidato Individual pela Empresa Cliente.
                </P>
                <P>
                  <Bold>Seleção e eliminação de Candidatos Individuais no Processo Seletivo:</Bold>{' '}
                  A Elevatte não interfere nem participa do Processo Seletivo, qualificação e
                  seleção realizadas pelas Empresas Clientes em seu processo, que são de sua
                  exclusiva responsabilidade. Da mesma forma, a Elevatte não se responsabiliza por
                  atrasos no processo de recrutamento e pela eliminação de Candidatos Individuais ao
                  longo do processo de recrutamento da Empresa Cliente.
                </P>
                <P>
                  <Bold>Negociação direta entre Candidato Individual e Empresa Cliente:</Bold> A
                  Elevatte não se envolve em negociações entre Empresas e Candidatos Individuais e
                  não se responsabiliza por quaisquer informações trocadas em contato direto entre a
                  Empresa Cliente e o Candidato Individual via e-mail, telefone ou outros meios de
                  comunicação.
                </P>
                <P>
                  <Bold>Links para outros sites:</Bold> A Plataforma pode conter links para sites de
                  terceiros, fornecidos para sua conveniência e não como um endosso da Elevatte ao
                  conteúdo desses sites. A Elevatte não controla e não é responsável pelo conteúdo,
                  políticas de privacidade ou práticas de sites de terceiros e não comentará sobre o
                  conteúdo ou a precisão dos materiais de terceiros. Se você acessar o link, o
                  Candidato Individual assume total responsabilidade.
                </P>
                <P>
                  <Bold>Problemas com o dispositivo do Candidato Individual:</Bold> Problemas que
                  ocorram no dispositivo do Candidato Individual, como falhas de sistema, problemas
                  com provedores, problemas de conexão com a internet, ataques de hackers, vírus,
                  interferências, pirataria ou outras violações de segurança em seu dispositivo que
                  causem danos, são de responsabilidade do Candidato Individual, que deve utilizar
                  meios que garantam a segurança de suas informações.
                </P>
                <P>
                  <Bold>Integração com outros sistemas:</Bold> A Elevatte não se responsabiliza
                  pelas integrações que a Empresa Cliente realiza com seus sistemas. A Elevatte
                  apenas fornece uma API pública e webhooks para permitir que a Empresa conecte a
                  Elevatte LTDA com outros sistemas.
                </P>
                <P>
                  A Elevatte não se responsabiliza pelo compartilhamento interno do token de
                  integração na Empresa Cliente e por quaisquer vazamentos de dados resultantes do
                  uso inadequado da API pela Empresa Cliente.
                </P>
              </ContentSection>
              <ContentSection>
                <ContentHeader>Preço</ContentHeader>
                <P>
                  Seu cadastro básico na Elevatte LTDA é gratuito e inclui a criação de uma página
                  com informações pessoais no site da Elevatte, a possibilidade de realizar testes
                  para mapeamento de perfil e estilo de trabalho, e a capacidade de visualizar
                  empresas e vagas cadastradas que correspondam ao seu perfil.
                </P>
                <P>
                  A Elevatte LTDA é GRATUITA para Candidatos Individuais, e não cobramos nenhuma
                  taxa pela sua participação em processos seletivos ou de admissão.
                </P>
                <P>
                  A Elevatte reserva-se o direito de estabelecer, modificar, aumentar ou eliminar
                  taxas a qualquer momento, sempre notificando previamente os Candidatos
                  Individuais, que decidirão se contratam os serviços pelo preço cobrado ou
                  atualizado.
                </P>
              </ContentSection>
              <ContentSection>
                <ContentHeader>Propriedade Intelectual</ContentHeader>
                <P>
                  O uso da Elevatte LTDA não implica, de forma alguma, na disponibilização do
                  código-fonte ou da estrutura interna do software ou de qualquer outra tecnologia
                  ou funcionalidade da plataforma, pois é de propriedade exclusiva da Elevatte.
                </P>
              </ContentSection>

              <ContentSection>
                <ContentHeader>Privacidade e Proteção de Dados</ContentHeader>
                <P>
                  A Elevatte, por meio da Elevatte LTDA, cumpre e segue as diretrizes estabelecidas
                  pela LGPD, priorizando a privacidade do Candidato Individual, de modo que os dados
                  pessoais coletados serão utilizados apenas para as finalidades detalhadas no Aviso
                  de Privacidade.
                </P>
                <P>
                  Ao se inscrever em um Processo Seletivo, você passa a fazer parte do Banco de
                  Talentos da Empresa Cliente, e seus Dados Pessoais serão mantidos lá mesmo após a
                  conclusão do Processo Seletivo. Se você desejar sair de um Banco de Talentos de
                  uma empresa específica ou parar de compartilhar seu currículo com as Empresas
                  Clientes da Elevatte no Portal de Talentos da Elevatte, você pode alterar as
                  configurações de privacidade diretamente em seu perfil.
                </P>
                <P>
                  A qualquer momento, você também pode solicitar a exclusão de sua conta, e assim,
                  seus Dados serão removidos ou anonimizados.
                </P>
                <P>
                  Se sua conta permanecer inativa, ou seja, sem acesso ou login por um período
                  superior a 5 anos, seus dados serão anonimizados e sua conta será excluída
                  permanentemente.
                </P>
                <P>
                  Além disso, quando você for contratado por uma Empresa Cliente, a Elevatte precisa
                  manter essas informações armazenadas para permitir que as empresas cumpram suas
                  obrigações trabalhistas ou para o exercício regular de direitos em eventuais
                  disputas judiciais. Nesse caso, só podemos excluir seus dados com a autorização
                  expressa da Empresa Cliente que o contratou.
                </P>
              </ContentSection>

              <ContentSection>
                <ContentHeader>Penalidades</ContentHeader>
                <P>
                  O não cumprimento de qualquer cláusula destes Termos de Uso pode levar ao
                  cancelamento do cadastro do Candidato Individual na Elevatte LTDA, e pode resultar
                  em responsabilidade civil e criminal.
                </P>
              </ContentSection>
              <ContentSection>
                <ContentHeader>Alterações, Modificações e Rescisão</ContentHeader>
                <P>
                  A Elevatte reserva-se o direito de modificar estes Termos a qualquer momento por
                  motivos legítimos, e é permitido a qualquer momento modificar ou descontinuar
                  (temporária ou permanentemente) a distribuição ou atualização da Elevatte LTDA.
                  Sempre que houver uma alteração significativa, faremos o possível para comunicá-la
                  a você. No entanto, recomendamos que você visite periodicamente esta página para
                  verificar os Termos mais recentes estabelecidos para o uso da Elevatte LTDA.
                </P>
                <P>
                  Se qualquer cláusula destes Termos se tornar inválida, ilegal ou inexequível, isso
                  não afetará, de forma alguma, a validade, legalidade e aplicabilidade das outras
                  cláusulas. A Elevatte não renuncia a nenhum de seus direitos ao não exigir o
                  cumprimento das disposições destes Termos, e poderá exercê-los conforme apropriado
                  e dentro dos prazos legais.
                </P>
                <P>
                  Esses Termos são válidos por prazo indeterminado, começando a partir da data de
                  Aceite Eletrônico pelo Candidato Individual.
                </P>
              </ContentSection>
              <ContentSection>
                <ContentHeader>Aceitação</ContentHeader>
                <P>
                  ESTES TERMOS TÊM A NATUREZA DE UM CONTRATO DE ADESÃO, E AO CONCORDAR COM AS
                  DISPOSIÇÕES AQUI CONTIDAS E COMEÇAR A UTILIZAR A ELEVATTE LTDA, VOCÊ CONCORDA
                  EXPRESSA, LIVRE E INFORMADAMENTE COM OS TERMOS E CONDIÇÕES. PORTANTO, VOCÊ SE
                  COMPROMETE A RESPEITAR IRREVOGAVELMENTE AS CONDIÇÕES AQUI ESTABELECIDAS E A
                  UTILIZAR OS SERVIÇOS OFERECIDOS ATRAVÉS DA ELEVATTE LTDA PELA ELEVATTE DE MANEIRA
                  RENTÁVEL E LEGAL.
                </P>
                <P>
                  O Candidato Individual entende e reconhece que possui capacidade jurídica para
                  celebrar estes Termos de Uso e utilizar o serviço.
                </P>
                <P>
                  Para utilizar a Elevatte LTDA e os Serviços, você - o Candidato Individual - deve
                  ter mais de 14 anos. Para firmar contratos com a Empresa Cliente, você deve
                  possuir plena capacidade jurídica de acordo com a legislação de seu país, ou seja,
                  ser maior de 18 anos, e menores (com mais de quatorze anos) devem ser assistidos
                  ou representados por seus pais, responsáveis ou curadores, conforme previsto em
                  lei, que serão considerados responsáveis por todos os atos praticados pelos
                  menores.
                </P>
              </ContentSection>
              <ContentSection>
                <ContentHeader>Legislação Aplicável e Foro</ContentHeader>
                <P>
                  Os Termos de Uso são regidos e interpretados de acordo com a legislação
                  brasileira, devido aos serviços serem oferecidos em território nacional, sendo o
                  foro competente para resolver questões relacionadas a este documento o foro de
                  Florianópolis – SC.
                </P>
              </ContentSection>
            </ContentContainer>
          </ContentWithPaddingLg>
        </Container>
      </div>
      <Footer />
    </main>
  )
}
