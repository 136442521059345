import React, { useState, useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import HeaderBase, { NavLinks, NavLink } from 'components/headers/light.js'
import { SectionHeading } from 'components/misc/Headings.js'
import { Container, ContentWithVerticalPadding } from 'components/misc/Layouts.js'
import bgLeft from '../../images/bg-left.png'

const BackgroundContainer = tw.div`bg-no-repeat bg-left-top pb-8 pt-16`
const FixedHeaderBackground = styled.div(({ isScrolled }) => [
  tw`fixed top-0 left-0 w-full z-50 transition duration-300 ease-in-out`,
  isScrolled ? tw`bg-white bg-opacity-75 backdrop-blur-sm shadow-lg` : tw`bg-transparent`,
])
const Header = tw(HeaderBase)`max-w-none`
const HeaderContainer = tw.div`max-w-screen-2xl mx-auto sm:px-8 pt-4 pb-0`
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center max-w-screen-2xl mx-auto sm:px-8`
const Column = tw.div``
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`
const Heading = tw(
  SectionHeading
)`text-left text-primary-900 leading-snug xl:text-6xl text-primary-gradient`

const handleNavClick = (id) => {
  const section = document.getElementById(id)
  if (section) {
    const targetPosition = section.getBoundingClientRect().top + window.scrollY
    const startPosition = window.scrollY
    const distance = targetPosition - startPosition
    const duration = 1500
    let start = null

    const ease = (t, b, c, d) => {
      t /= d / 2
      if (t < 1) return (c / 2) * t * t + b
      t--
      return (-c / 2) * (t * (t - 2) - 1) + b
    }

    const animateScroll = (timestamp) => {
      if (!start) start = timestamp
      const progress = timestamp - start
      window.scrollTo(0, ease(progress, startPosition, distance, duration))
      if (progress < duration) {
        requestAnimationFrame(animateScroll)
      }
    }

    requestAnimationFrame(animateScroll)
  }
}

export default ({
  heading = 'Better, Faster and Cheaper Cloud.',
  navLinks = [
    <NavLinks key={1}>
      <NavLink
        href="#home"
        onClick={(e) => {
          e.preventDefault()
          handleNavClick('home')
        }}
      >
        Home
      </NavLink>
      <NavLink
        href="#features"
        onClick={(e) => {
          e.preventDefault()
          handleNavClick('features')
        }}
      >
        Features
      </NavLink>
      {false && (
        <NavLink
          href="#pricing"
          onClick={(e) => {
            e.preventDefault()
            handleNavClick('pricing')
          }}
        >
          Planos
        </NavLink>
      )}
      <NavLink
        href="#blog"
        onClick={(e) => {
          e.preventDefault()
          handleNavClick('blog')
        }}
      >
        Blog
      </NavLink>
    </NavLinks>,
  ],
}) => {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <FixedHeaderBackground isScrolled={isScrolled}>
        <HeaderContainer>
          <Header links={navLinks} />
        </HeaderContainer>
      </FixedHeaderBackground>
      <BackgroundContainer
        style={{ backgroundImage: `url(${bgLeft})`, backgroundRepeat: 'no-repeat' }}
      >
        <Container>
          <ContentWithVerticalPadding>
            <Row>
              <TextColumn>
                <Heading>{heading}</Heading>
              </TextColumn>
            </Row>
          </ContentWithVerticalPadding>
        </Container>
      </BackgroundContainer>
    </>
  )
}
